export const LOGIN = "/";
export const DASHBOARD = "/dashboard";
export const TWITTER_FEED = '/twitterfeed';
export const AI_DECISION_MAKER = '/aidecisionmakers';
export const AI_LEADS = "/aileads";
export const AI_PATH = '/aipath';
export const AI_PROFILE = '/aiprofile';
export const AI_PROFILE_FORM_TABLE = '/aiprofileformtable';
export const AI_PROSPECT_PROFILE = '/aiprospectprofile';
export const COMPANY_PROFILE_SCREEN = '/companyprofilescreen'
export const CONTACT_US = '/contactus'
export const FAQ_SCREEN = '/faqscreen'
export const JOI_TRAINING = '/joitraning'
export const COMING_SOON = '/comingSoon'
export const LOGIN_CALLBACK = '/login/callback'
export const PDF_SAMPLE = '/pdfSample'