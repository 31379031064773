import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import "./Style.css";
import AiLeadsAction from "../../Components/AiLeads/AiLeadsButton/AiLeadsAction";
import DecisionMakerTable from "../../Components/DecisionMaker/DecisionMakerTable";
import DecisionmakersFilter from "../../Components/DecisionMaker/DecisionFilter/Index";
import { toast } from "react-toastify";
import axios from "axios";
import { APIUrlOne, GetUserId } from "../../Utils/Utils";
import Loader from "../../Components/Loader/Loader";

const DecisionMaker = () => {
  const userId = GetUserId();
  const [loading, setLoading] = useState(false);
  const [tableCommingData, setTableCommingData] = React.useState([]);
  const [AiProspectData, setAiProspectData] = useState([])
  const [firstFilterData, setFirstFilterData] = useState([]);
  const [istableDataFilter, setIstableDataFilter] = React.useState(false);
  const [currentLeadsLength, setCurrentLeadsLength] = React.useState('');
  const [isSalesForceTrigger, setIsSalesForceTrigger] = useState(false);
  const [isDecisionMakerExcel, setIsDecisionMakerExcel] = useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [showData, setShowData] = React.useState([]);
  const [lastdata, setlastdata] = React.useState([]);
  const [skip, setSkip] = React.useState(0);
  const [decisionMakerData, setDecisionMakerData] = React.useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [statsCountDecisionMaker, setStatsCountDecisionMaker] = useState(0);
  const [applyFilter, setIsApplyFilter] = useState(false);
  const [searchPeople, setSearchPeople] = useState('');
  const [PeopleData, setPeopleData] = useState([]);
  const [isfetchData, setIsfetchData] = React.useState(false);
  const [dataSelectedFilter, setDataSelectedFilter] = useState('');
  const [prospectsProfileRecord, setProspectsProfileRecord] = useState(false);
  const [AiProfileCheck, SetAiProfileCheck] = React.useState([]);
  const [showSearchdata, setshowSearchdata] = React.useState(false);
  const [FilterData, setFilterData] = useState([]);
  const perPage = 50;
  const [totalPages, setTotalPages] = React.useState(1);
  const [hitConnStrength,sethitConnStrength]=useState(false)
  const validateFilters = () => {
    if (!selectedData?.length && !showData?.length && !lastdata?.length
    ) {
      toast.error("Please Select Filters");
      return false;
    }
    return true;
  }

  const duplicateHandlePass = (e) => {
    if (!validateFilters()) return;
    if (dataSelectedFilter?.id) {
      setDataSelectedFilter(null);
      SetAiProfileCheck([]);
    }
    setLoading(true);
    setIsApplyFilter(true);
    setSkip(0);
    const data = {};
    data.categories = selectedData;
    data.decision_maker = showData?.[0];
    data.josf_status = lastdata?.[0]
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/apply_people_filter?limit=50&skip=0`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)
        if (e?.status === 200) {
          const comingData = e?.data?.data;
          const statsCount = e?.data?.count;
          setStatsCountDecisionMaker(statsCount);
          setFirstFilterData(comingData);
          if (comingData.length === 0 || comingData.length % 50 !== 0) {
            setHasMore(false);
          } else {
            setTimeout(() => {
              setHasMore(true);
            }, 1000);
          }
          if (skip > 1) {
            setTableCommingData(comingData)
          } else {
            setTableCommingData(comingData);
          }

          toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handlePassSubmit = () => {
    if (!validateFilters()) return;
    if (dataSelectedFilter?.id) {
      setDataSelectedFilter(null);
      SetAiProfileCheck([]);
    }
    if (statsCountDecisionMaker <= tableCommingData.length) return;
    setHasMore(false);
    setLoading(true);
    const data = {};
    data.categories = selectedData;
    data.decision_maker = showData?.[0];
    data.josf_status = lastdata?.[0]
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/apply_people_filter?limit=${perPage}&skip=${skip ? skip * 50 : 0}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)
        if (e?.status === 200) {
          const comingData = e?.data?.data;
          const statsCount = e?.data?.count;
          setStatsCountDecisionMaker(statsCount);
          if (comingData.length === 0 || comingData.length % 50 !== 0) {
            setHasMore(false);
          } else {
            setTimeout(() => {
              setHasMore(true);
            }, 1000);
          }
          if (skip > 0) {
            setTableCommingData(prevdata => [...prevdata, ...comingData,])
          } else {
            setTableCommingData(comingData);
          }

          toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const decisionMakerProfile = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/get_filter_people?user_id=${userId}`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          setAiProspectData(e?.data?.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    decisionMakerProfile()
  }, [])

  useEffect(() => {
    if (dataSelectedFilter) {
      DecisionMakerDataListing();
    }
  }, [dataSelectedFilter]);

  const DecisionMakerDataListing = () => {
    setLoading(true);
    const data = {};
    data.user_id = userId;
    data.name = dataSelectedFilter?.name;
    data.decision_maker =
      dataSelectedFilter?.decisionmaker === "True" ? true : false;
    data.primary_organization = dataSelectedFilter?.primary_organization;
    data.country = dataSelectedFilter?.country;
    data.state = dataSelectedFilter?.state;
    data.josf_status = dataSelectedFilter?.josf_status === "True" ? true : false;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/apply_people_filter?limit=${perPage}&skip=${skip ? skip * 50 : 0}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          // setTotalPages(10);
          setStatsCountDecisionMaker(response?.data?.count);
          const statsCount = response?.data?.count;
          const recordDivide = statsCount / perPage;
          const formatedTotal = Math?.round(recordDivide);
          setTotalPages(formatedTotal);
          setProspectsProfileRecord(true);
          if (response?.data?.data?.length === 0) {
            setHasMore(false);
          } else {
            // setTotalRecords(response?.data?.count)
            // setResultRetrieve([...resultRetrieve, ...response?.data?.data]);
            setTableCommingData(response?.data?.data);
          }

          if (response?.data?.data?.length > 49 && skip > 0) {
            // setTableShowResult(true);
            setTableCommingData([...tableCommingData, ...response?.data?.data]);
            toast.success(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {

    setTimeout(() => {
      if (skip > 0 && prospectsProfileRecord) {
        if (tableCommingData?.length) {
          DecisionMakerDataListing();
        }
      }
    }, 150)
  }, [skip, prospectsProfileRecord])


  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <Layout>
        <div className="child-section-of-everypage">
          <AiLeadsAction
            currentLeadsLength={currentLeadsLength}
            setIsSalesForceTrigger={setIsSalesForceTrigger}
            isSalesForceTrigger={isSalesForceTrigger}
            setIsDecisionMakerExcel={setIsDecisionMakerExcel}
            statsCountDecisionMaker={statsCountDecisionMaker}
            setPeopleData={setPeopleData}
            PeopleData={PeopleData}
            setIsfetchData={setIsfetchData}
            setshowSearchdata={setshowSearchdata}
            showSearchdata={showSearchdata}
            setFilterData={setFilterData}
            FilterData={FilterData}
            sethitConnStrength={sethitConnStrength}
          />

          <DecisionmakersFilter
            tableCommingData={tableCommingData}
            setTableCommingData={setTableCommingData}
            istableDataFilter={istableDataFilter}
            setIstableDataFilter={setIstableDataFilter}
            duplicateHandlePass={duplicateHandlePass}
            setSelectedData={setSelectedData}
            selectedData={selectedData}
            setShowData={setShowData}
            showData={showData}
            setlastdata={setlastdata}
            lastdata={lastdata}
            setStatsCountDecisionMaker={setStatsCountDecisionMaker}
            AiProspectData={AiProspectData}
            setDataSelectedFilter={setDataSelectedFilter}
            dataSelectedFilter={dataSelectedFilter}
            setSkip={setSkip}
            setProspectsProfileRecord={setProspectsProfileRecord}
            SetAiProfileCheck={SetAiProfileCheck}
            AiProfileCheck={AiProfileCheck}
            setTotalPages={setTotalPages}
            totalPages={totalPages}
          />
          <DecisionMakerTable
            tableCommingData={tableCommingData}
            setTableCommingData={setTableCommingData}
            istableDataFilter={istableDataFilter}
            setCurrentLeadsLength={setCurrentLeadsLength}
            isSalesForceTrigger={isSalesForceTrigger}
            isDecisionMakerExcel={isDecisionMakerExcel}
            setIsDecisionMakerExcel={setIsDecisionMakerExcel}
            setIstableDataFilter={setIstableDataFilter}
            handlePassSubmit={handlePassSubmit}
            selectedData={selectedData}
            setSkip={setSkip}
            skip={skip}
            setDecisionMakerData={setDecisionMakerData}
            decisionMakerData={decisionMakerData}
            setHasMore={setHasMore}
            hasMore={hasMore}
            firstFilterData={firstFilterData}
            setIsApplyFilter={setIsApplyFilter}
            applyFilter={applyFilter}
            PeopleData={PeopleData}
            setIsfetchData={setIsfetchData}
            isfetchData={isfetchData}
            prospectsProfileRecord={prospectsProfileRecord}
            dataSelectedFilter={dataSelectedFilter}
            setStatsCountDecisionMaker={setStatsCountDecisionMaker}
            statsCountDecisionMaker={statsCountDecisionMaker}
            FilterData={FilterData}
            setTotalPages={setTotalPages}
            totalPages={totalPages}
            hitConnStrength={hitConnStrength}
            />
        </div>
      </Layout>
    </>
  );
};
export default DecisionMaker;