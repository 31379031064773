import * as React from "react";
import "./AboutJoi.css"
import JoiImage from "../../Assets/JoiImage.jpg"
import TraningImage from "../../Assets/TraningImage.jpg"
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal } from "@mui/material";


export default function AboutJoi() {
    const [ JoiVideoTutorial, setJoiVideoTutorial] = React.useState(false);
    const handleCloseOverview = () => setJoiVideoTutorial(false);
    const handleOpenOverview = () => setJoiVideoTutorial(true);



    const openVideoOverview = () => {
        handleOpenOverview();
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #ffffff",
        boxShadow: 24,
        p: 4,
    };

  return (
    <>
<div>
    <div className="about-joi-main-heading">
        <h2 >LEARN ALL ABOUT JOI</h2>
    </div>
    <div className="Aboutjoi-line"></div>

    <div className="flex-cotainer-of-about-joi-screen">

        <div className="child-container-ofAbout-screen">
            <h3>The JOI Video Tutorial</h3>
            <p> This video provides a comprehensive step by step guide that will enable you to effortlessly navigate and master the JOI platform.</p>
            <p>Video Length: 12:17</p>
            <img  className="about-JoiImage" onClick={openVideoOverview} src={JoiImage} alt="" />
        </div>

        <div  className="child-container-ofAbout-screen">
        <h3>One-on-One Training</h3>
            <p> To ensure you make the most of this powerful new tool,we would like to invite you to a one-on-one training session.</p>
            <p className="Simplyptag">Simply click the link below to schedule your training time.</p>
            <a href="https://app.smartsheet.com/b/home?lgt=wf&lrt=s&EQBCT=ecb4f8bd4aca4eb4943a2e2eaba3cbaf">Click Here to Schedule</a><br></br>
        <img className="TraningImage"  src={TraningImage} alt="" />
        </div>
    </div>
</div>

              {/* The Joi Video Tutorial modal popup  */}
                 <Modal
                    open={ JoiVideoTutorial}
                    onClose={handleCloseOverview}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="modal-joiTraining-videos"
                >
                    <Box sx={style} className="box-video-modals">
                        <div className="box-section-relative">
                            <div className="cross-icon-section" onClick={handleCloseOverview}>
                                <CloseIcon />
                            </div>
                            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                                <iframe
                                    src="https://player.vimeo.com/video/943726224?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    style={{
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    title="JOI PILOT Overview"
                                ></iframe>
                            </div>
                        </div>
                    </Box>
                </Modal>

               

    </>
  );
}