import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import "./Style.css";
import AiLeadsTable from "../../Components/AiLeads/Table/Table";
import LeadsFilter from "../../Components/AiLeads/Filter/LeadsFilter";
import AiLeadsAction from "../../Components/AiLeads/AiLeadsButton/AiLeadsAction";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { APIUrlOne, GetUserId } from "../../Utils/Utils";
import moment from "moment";

const AILeads = () => {
  const [tableCommingData, setTableCommingData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [istableDataFilter, setIstableDataFilter] = React.useState(false);
  const [currentLeadsLength, setCurrentLeadsLength] = React.useState('');
  const [isDecisionMakerExcel, setIsDecisionMakerExcel] = useState(false);
  const [isSalesForceTrigger, setIsSalesForceTrigger] = useState(false);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showData, setShowData] = React.useState([]);
  const [showlast, setShowlast] = React.useState([]);
  const [jScoredata, setJscore] = React.useState([]);
  const [statsCount, setStatsCount] = useState(0);
  const [leadsProfileData, setLeadsProfileData] = useState([]);
  const [dataSelectedFilter, setDataSelectedFilter] = useState('');
  const [AiProfileCheck, SetAiProfileCheck] = React.useState([]);
  const [isLeadsProfileRecords, setIsLeadsProfileRecords] = useState(false);
  const [aiProfileChecked, setAiProfileChecked] = useState(false);
  const perPage = 50;
  const userId = GetUserId();
  const validateFilters = () => {
    if (!selectedData?.length && !showlast?.length && !jScoredata?.length) {
      toast.error("Please Select Filters");
      return false;
    }
    return true;
  }
  const duplicateApply = (e) => {
    if (!validateFilters()) return;
    if (dataSelectedFilter?.id) {
      setDataSelectedFilter(null);
      SetAiProfileCheck([]);
    }
    setLoading(true);
    const data = {};
    data.categories = selectedData;
    data.revenue_range = showlast;
    data.j_score = jScoredata?.[0];
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/apply_org_filter?limit=${perPage}&skip=0`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)

        const comingData = e?.data?.data;
        const statsCount = e?.data?.count;
        setStatsCount(statsCount);
        const recordDivide = statsCount / perPage;
        const formatedTotal = Math?.round(recordDivide);
        setTotalPages(formatedTotal);
        setPage(1);
        if (comingData.length === 0 || comingData.length % 50 !== 0) {
          setHasMore(false);
        } else {
          setTimeout(() => {
            setHasMore(true);
          }, 1000);
        }
        if (page > 1) {
          setTableCommingData(comingData);
        }
        else {
          setTableCommingData(comingData);
        }
        if (comingData.length === 0) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleApply = (e) => {
    if (!validateFilters()) return;
    if (dataSelectedFilter?.id) {
      setDataSelectedFilter(null);
      SetAiProfileCheck([]);
    }
    if (statsCount <= tableCommingData.length) return;

    if (loading) return;
    setHasMore(false);
    setLoading(true);
    const data = {};
    data.categories = selectedData;
    data.revenue_range = showlast;
    data.j_score = jScoredata?.[0];
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/apply_org_filter?limit=${perPage}&skip=${(page - 1) * 50}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)
        const comingData = e?.data?.data;
        const statsCount = e?.data?.count;
        setStatsCount(statsCount);
        const recordDivide = statsCount / perPage;
        const formatedTotal = Math?.round(recordDivide);
        setTotalPages(formatedTotal);

        if (comingData.length % 50 !== 0 || comingData.length === 0) {

          setHasMore(false);
        } else {
          setTimeout(() => { setHasMore(true); }, 1000)
        }
        if (page > 1) {
          setTableCommingData(prevData => [...prevData, ...comingData]);
        }
        else {
          setTableCommingData(comingData);
        }
        if (comingData.length === 0) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {

    setTimeout(() => {
      if (page > 0 && !isLeadsProfileRecords) {
        if (tableCommingData?.length) {
          handleApply();
        }
      }
    }, 150)
  }, [page, !isLeadsProfileRecords])
  const LeadsProfile = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/get_filter_org?user_id=${userId}`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          setLeadsProfileData(e?.data?.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    LeadsProfile()
  }, [])

  useEffect(() => {
    if (dataSelectedFilter) {
      LeadsProfileRecords();
    }
  }, [dataSelectedFilter])
  const formatedDateFrom = moment(dataSelectedFilter?.last_funding_at_from).format("YYYY-MM-DD");
  const formatedDateTo = moment(dataSelectedFilter?.last_funding_at_to).format("YYYY-MM-DD");
  const minFundingRounds = parseInt(dataSelectedFilter?.num_funding_rounds_min);
  const maxFundingRounds = parseInt(dataSelectedFilter?.num_funding_rounds_max);
  const TotalFundingRounds = [minFundingRounds, maxFundingRounds];
  const LeadsProfileRecords = () => {
    setLoading(true);
    const data = {};
    data.name = dataSelectedFilter?.name;
    data.user_id = userId;
    if (Array.isArray(dataSelectedFilter?.categories) && dataSelectedFilter?.categories.every(elem => elem === null)) {
      data.categories = null;
    } else {
      data.categories = dataSelectedFilter?.categories ? [dataSelectedFilter?.categories] : null;
    }

    data.revenue_range = dataSelectedFilter?.revenue_range ? [dataSelectedFilter?.revenue_range] : null;
    data.num_employees = dataSelectedFilter?.num_employees ? dataSelectedFilter?.num_employees : null;
    data.state = dataSelectedFilter?.state ? dataSelectedFilter?.state : null;
    data.country = dataSelectedFilter?.country ? dataSelectedFilter?.country : null;
    data.ipo_status = dataSelectedFilter?.ipoStatus ? dataSelectedFilter?.ipoStatus : null;
    data.last_funding_type = dataSelectedFilter?.last_funding_type ? dataSelectedFilter?.last_funding_type : null;
    data.last_funding_at_from = formatedDateFrom === 'Invalid date' ? null : formatedDateFrom;
    data.last_funding_at_to = formatedDateTo === 'Invalid date' ? null : formatedDateTo;
    data.num_funding_rounds = (minFundingRounds || maxFundingRounds) ? TotalFundingRounds : null;
    data.last_funding_total = dataSelectedFilter?.last_funding_total ? dataSelectedFilter?.last_funding_total : null;
    data.j_score = dataSelectedFilter?.j_score ? dataSelectedFilter?.j_score : null;
    const option = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/apply_org_filter?limit=${perPage}&skip=${(page - 1) * 50}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          // setDataCount(response?.data?.count);
          setStatsCount(response?.data?.count);

          const recordDivide = statsCount / perPage;
          const formatedTotal = Math?.round(recordDivide);
          setTotalPages(formatedTotal);
          setIsLeadsProfileRecords(true);
          if (response?.data?.data?.length === 0) {
            setHasMore(false);
          } else {
            // setTotalRecords(response?.data?.count)
            // setResultRetrieve([...resultRetrieve, ...response?.data?.data]);
            setTableCommingData([...tableCommingData, ...response?.data?.data])
          }
          if (response?.data?.data?.length) {
            // setTableShowResult(true);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  }

  useEffect(() => {

    setTimeout(() => {

      if (page > 0 && isLeadsProfileRecords === true) {
        if (tableCommingData?.length) {
          LeadsProfileRecords();
        }
      }
    }, 150)
  }, [page])
  return (
    <>
      <Layout>
        {loading ? <Loader /> : null}
        <div className="child-section-of-everypage  ">
          {/* <InfiniteScroll
            dataLength={tableCommingData.length}
            next={() => {

              if (hasMore) {
                setHasMore(false);
                setPage(page + 1)
              }

            }}
            hasMore={hasMore}
          /> */}
          <AiLeadsAction
            currentLeadsLength={currentLeadsLength}
            setIsDecisionMakerExcel={setIsDecisionMakerExcel}
            setIsSalesForceTrigger={setIsSalesForceTrigger}
            isSalesForceTrigger={isSalesForceTrigger}
            statsCount={statsCount}

          />
          <LeadsFilter
            showlast={showlast}
            setShowlast={setShowlast}
            setJscore={setJscore}
            jScoredata={jScoredata}
            selectedData={selectedData}
            showData={showData}
            setShowData={setShowData}
            setSelectedData={setSelectedData}
            handleApply={() => { duplicateApply() }}
            tableCommingData={tableCommingData}
            setTableCommingData={setTableCommingData}
            istableDataFilter={istableDataFilter}
            setIstableDataFilter={setIstableDataFilter}
            setStatsCount={setStatsCount}
            setPage={setPage}
            page={page}
            leadsProfileData={leadsProfileData}
            setDataSelectedFilter={setDataSelectedFilter}
            dataSelectedFilter={dataSelectedFilter}
            setIsLeadsProfileRecords={setIsLeadsProfileRecords}
            SetAiProfileCheck={SetAiProfileCheck}
            AiProfileCheck={AiProfileCheck}
            setAiProfileChecked={setAiProfileChecked}

          />
          <AiLeadsTable
            handleApply={handleApply}
            tableCommingData={tableCommingData}
            setTableCommingData={setTableCommingData}
            istableDataFilter={istableDataFilter}
            setCurrentLeadsLength={setCurrentLeadsLength}
            setIstableDataFilter={setIstableDataFilter}
            isSalesForceTrigger={isSalesForceTrigger}
            isDecisionMakerExcel={isDecisionMakerExcel}
            setIsDecisionMakerExcel={setIsDecisionMakerExcel}
            setPage={setPage}
            page={page}
            setTotalPages={setTotalPages}
            totalPages={totalPages}
            statsCount={statsCount}
            dataSelectedFilter={dataSelectedFilter}
            setStatsCount={setStatsCount}
            aiProfileChecked={aiProfileChecked}
            setDataSelectedFilter={setDataSelectedFilter}
            SetAiProfileCheck={SetAiProfileCheck}

          />
        </div>
      </Layout>
    </>
  );
};
export default AILeads;