import * as React from "react";
import "../Filter/LeadsFilter.css";
import Filter from "../../../Assets/FilterOval.svg";
import ReplayIcon from "@mui/icons-material/Replay";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { APIUrlOne } from "../../../Utils/Utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Filtericon from "../../../Assets/Filtericon.svg";
import FormControlSelect from "../../FormControl/Index";
import axios from "axios";
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from "@mui/material";
export default function LeadsFilter({ setTableCommingData, setIstableDataFilter,
  handleApply,
  setSelectedData,
  selectedData,
  setShowData,
  setJscore, jScoredata, showlast, setShowlast,
  setStatsCount,
  setPage,
  leadsProfileData,
  setDataSelectedFilter,
  dataSelectedFilter,
  page,
  setIsLeadsProfileRecords,
  SetAiProfileCheck,
  AiProfileCheck,
  setAiProfileChecked
}) {
  const [isLoadFilters, setIsLoadFilters] = React.useState(false);
  const [selectedIndustry, setSelectedIndustry] = React.useState("");
  const [selectedRevenue, setSelectedRevenue] = React.useState("");
  const [selectedJscore, setSelectedJscore] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [aiLeadsFilters, setAiLeadsFilters] = React.useState({
    industry: "",
    ravenue: "",
    jScore: "",
  });
  const [AiProfileData, setAiProfileData] = React.useState();

  const [filterLeadData, setFilterLeadData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handelAiProfileData = (event, name) => {
  //   let updatedChecked = [...AiProfileCheck];
  //   if (event.target.checked) {
  //     updatedChecked = [name];
  //   } else {
  //     updatedChecked = updatedChecked.filter(item => item !== name);
  //   }
  //   SetAiProfileCheck(updatedChecked);
  // };
  const handelAiProfileData = (event, item) => {
    let updatedChecked = [...AiProfileCheck];
    if (event.target.checked) {
      updatedChecked = [item];  // Store the full object
      setDataSelectedFilter(item);  // Set the full object when checked
      setPage(1);
      handleMenuClose();
      setTableCommingData([]);
      setSelectedData([]);
      setShowlast([]);
      setJscore([]);
      setAiProfileChecked(true);
    } else {
      updatedChecked = updatedChecked.filter((i) => i.name !== item.name);
      setDataSelectedFilter(null);  // Clear the data when unchecked
      setTableCommingData([]);
      setPage(1);
      setIsLeadsProfileRecords(false);
      setIstableDataFilter(true);
      handleMenuClose();
    }
    SetAiProfileCheck(updatedChecked);
  };
  const handleAiProFIleChange = (event) => {
    const value = event.target.value;
    setAiProfileData(value);
  };
  const LeadsFilters = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${APIUrlOne()}/v1/org_filters`,
    };
    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.request?.response);
        setAiLeadsFilters(data);
      })
      .catch(() => { });
  };
  React.useEffect(() => {
    if (isLoadFilters) {
      LeadsFilters();
    } else {
      setIsLoadFilters(true);
    }

  }, [isLoadFilters]);
  const handleIndustryChange = (event) => {
    const value = event.target.value;
    setSelectedIndustry(value);
  };
  const handleRevenueChange = (event) => {
    const value = event.target.value;
    setSelectedRevenue(value);
  };
  const handleJscoreChange = (event) => {
    const value = event.target.value;
    setSelectedJscore(value);
  };
  const handeldata = (event, item) => {
    if (event.target.checked) {
      setSelectedData([...selectedData, item]);
    } else {
      setSelectedData(selectedData?.filter((uncheck) => uncheck !== item));
    }
  };
  const handeldatajScore = (event, item) => {
    if (event.target.checked) {
      setJscore([...jScoredata, item]);
    } else {
      setJscore(jScoredata.filter((uncheck) => uncheck !== item));
    }
  };
  const handeldatalast = (event, item) => {
    if (event.target.checked) {
      setShowlast([...showlast, item]);
    } else {
      setShowlast(showlast.filter((uncheck) => uncheck !== item));
    }
  };
  const ResetFilterData = () => {
    setSelectedData([]);
    setShowData([]);
    setShowlast([]);
    setJscore([]);
    if (dataSelectedFilter?.id) {
      setDataSelectedFilter(null);
      SetAiProfileCheck([]);
      setTableCommingData([]);
      setIstableDataFilter(true);
      setPage(1);
    }
    if (selectedData?.length || showlast?.length || jScoredata?.length) {
      setPage(1);
      setTableCommingData([]);
      setIstableDataFilter(true);
      // setStatsCount('');
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openSaveProfile, setOpenSavedProfile] = React.useState(false);
  const handleMenuOpen = (event) => {
    // setAnchorEl(event.currentTarget);
    setOpenSavedProfile(true)
  };
  const handleMenuClose = () => {
    // setAnchorEl(null);
    setOpenSavedProfile(false);
  };
  const isDisabled = (name) => AiProfileCheck?.length > 0 && !AiProfileCheck.some((i) => i.name === name);
  React.useEffect(() => {
    if (leadsProfileData) {
      const dataFilter = leadsProfileData?.filter((item) => item?.default === true);
      setFilterLeadData(dataFilter);
    }
  }, [leadsProfileData])
  const combinedData = [
    ...filterLeadData,
    ...leadsProfileData?.filter(item => !filterLeadData?.includes(item))
  ];
  // const selectedCheckBox = (item) => {
  //   if (item !== dataSelectedFilter) {
  //     setDataSelectedFilter(item);
  //     handleMenuClose();
  //   }

  // }
  // React.useEffect(() => {
  //   if (dataSelectedFilter !== null) {
  //     handleMenuClose();
  //   }
  // }, [dataSelectedFilter]);
  return (
    <React.Fragment>
      <div className="main-container-ofAi-leads-filters">
        <div className="outer-main-for-filtersand-buttons">
          <section className="Leads-Filter-main-container">
            <div className="AILeads-Filter1st">
              <div>
                <div className="AiLeads-filter-icons">
                  <img src={Filter} alt="" />
                </div>
                <div>
                  <img src={Filtericon} alt="" />
                </div>
              </div>
            </div>
            <div className="AILeads-Filter2nddrop-down">
              <FormControlSelect
                formControlData={{
                  value: selectedIndustry,
                  handleChange: handleIndustryChange,
                  selectedData: selectedData,
                  handleCheckboxChange: handeldata,
                  dataList: aiLeadsFilters?.data?.categories,
                  checked: selectedData,
                }}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                placeholder="Industry"
              />
            </div>
            <div className="Leads-Filter-Laststdrop-down">
              <FormControlSelect
                formControlData={{
                  value: selectedRevenue,
                  handleChange: handleRevenueChange,
                  selectedData: showlast,
                  handleCheckboxChange: handeldatalast,
                  isRevenue: true,
                  dataList: aiLeadsFilters?.data?.revenue_range,
                  checked: showlast,
                }}
                placeholder="Revenue"
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
              />
            </div>
            <div className="JScoreContainer">
              <FormControlSelect
                formControlData={{
                  value: selectedJscore,
                  handleChange: handleJscoreChange,
                  selectedData: jScoredata,
                  handleCheckboxChange: handeldatajScore,
                  dataList: aiLeadsFilters?.data?.j_score?.slice(1),
                  checked: jScoredata,
                }}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                placeholder="JOI Score"
              />
            </div>
          </section>
          <div className="apply-hit-button">
            <div className="inner-apply-button-container" onClick={handleApply}>
              <FileDownloadDoneIcon className="apply-tick-icon" />
              <button className="AileadsFilter-Apply-button" variant="contained">
                Apply
              </button>
            </div>
          </div>
          <div onClick={ResetFilterData} className="Reset-filter-container">
            <div className="inner-reset-filter">
              <div>
                <ReplayIcon className="reset-filter-icon" />
              </div>
              <div>
                <p>Reset Filter</p>
              </div>
            </div>
          </div>

        </div>

        <div className="MyAiProfile-data-list-container">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={AiProfileData}
              onChange={handleAiProFIleChange}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ "aria-label": "Without label" }}

              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <div>
                      <em className="setSelectProfile-ui">My AI Profile</em>
                      <p className="SelectedValue">
                        {AiProfileCheck?.length === 0 ? "Select" : `Selected: ${AiProfileCheck?.length}`}
                      </p>              </div>
                  );
                }
                return selected;
              }}
              onOpen={handleMenuOpen}
              onClose={handleMenuClose}
              open={openSaveProfile}
            // MenuProps={{
            //   anchorEl: anchorEl,
            //   open: Boolean(anchorEl),
            //   onClose: handleMenuClose,
            // }}
            >
              <div className="AI-leade-checkbox-and-data-list-last-filter">
                <div className="checkbox-select-data">
                  {combinedData.map((item) => (
                    <MenuItem
                      className={`menuitems-last-filter ${isDisabled(item.name) ? 'disabled-menuitem' : ''}`}
                      key={item.id}
                      value={item.name}
                      onClick={(event) => event.stopPropagation()}
                      sx={{
                        color: isDisabled(item.name) ? 'lightgray' : 'black',
                      }}
                    >
                      <Checkbox className="Newcheckbox"
                        // checked={AiProfileCheck.includes(item.name)}
                        checked={AiProfileCheck?.some((i) => i.name === item.name)}
                        onChange={(event) =>
                          handelAiProfileData(event, item)
                        } color="success"
                        disabled={
                          AiProfileCheck.length > 0 && !AiProfileCheck.some((i) => i.name === item.name)
                        }
                      // onClick={() => selectedCheckBox(item)}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </div>
              </div>
            </Select>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
}