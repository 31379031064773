import * as React from "react";
import "./Style.css";
import Button from "@mui/material/Button";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LabelInput from "../../LabelInputFields/Index";
import Stack from "@mui/material/Stack";
import ClearIcon from "@mui/icons-material/Clear";
import { AI_PROFILE_FORM_TABLE } from "../../../Utils/Constants";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "8px",
};
export default function NewAILeadsButton({ validationSaveSearch, handlesave, handleModelSave, modalOpen,
  setModalOpen, setDetailsFormData, detailsFormData,
  prefilledData,
  handleModelUpdate,
  addName,
  setAddName

}) {
  const handleOpen = () => {
    if (!validationSaveSearch()) return;
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);
  const handleCancel = () => {
    handleClose();
  };
  const [modalinput, setmodalinput] = React.useState("");
  const handleInputChange = (e) => {
    setmodalinput(e.target.value);
  };
  return (
    <>
      <section className={window.location.pathname === AI_PROFILE_FORM_TABLE ? "AI_PROFILE_FORM_TABLEdata" : "Leades-filter-drop-down-button-main-container"}>
        <div>
          <h3 className="new-ai-leads-heading">{prefilledData ? prefilledData?.name : "New AI Leads Profile"}</h3>
        </div>
        <div>
          <div>
            <Button
              className={prefilledData ? "Upadte-search-button" : "save-search-button"}
              style={{ textTransform: "none" }}
              onClick={handleOpen}
            >
              <FolderOpenIcon />
              <p className={prefilledData ? "Update-search-profile" : "save-search-profile"} onClick={handlesave}> {prefilledData ? "Update Search" : "Save Search"} </p>
            </Button>
            <Modal
              open={modalOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="vrufyerv">
                <Box sx={style}>
                  <div className="modal-head-heading-crossicon">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="enetr-name-heading-modal"
                    >
                      {prefilledData ? 'Update search name' : 'Enter search name'}
                    </Typography>
                    <ClearIcon
                      onClick={handleCancel}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <label htmlFor="" className="search-name-label-modal">
                      Search name
                    </label>
                    <LabelInput
                      value={addName}
                      onChange={(e) => setAddName(e.target.value)}
                    />
                  </Typography>
                  <Stack>
                    <div className="button-cancel-save-modal">
                      <Button
                        variant="type"
                        className="cancel-button-modal"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      {prefilledData ? (
                        <Button
                          onClick={() => handleModelUpdate()}
                          variant="type"
                          className={
                            addName
                              ? "save-button-modal"
                              : "save-button-modalclass"
                          }
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleModelSave()}
                          variant="type"
                          className={
                            addName
                              ? "save-button-modal"
                              : "save-button-modalclass"
                          }
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </Stack>
                </Box>
              </div>
            </Modal>
          </div>
        </div>
      </section>
    </>
  );
}