import { useEffect, useRef } from 'react';

const useInactivityTimer = (onLogout, timeout = 1200000, isActive = true) => {
  const timerRef = useRef(null);

  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    if (isActive) {
      timerRef.current = setTimeout(onLogout, timeout);
    }
  };

  const handleActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    if (!isActive) {
      if (timerRef.current) clearTimeout(timerRef.current);
      return;
    }

    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    events.forEach(event => window.addEventListener(event, handleActivity));

    resetTimer();

    return () => {
      events.forEach(event => window.removeEventListener(event, handleActivity));
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isActive]);

  return null;
};

export default useInactivityTimer;
