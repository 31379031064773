import React, { useEffect, useState } from "react";
import './Login.css';
import { useOktaAuth } from "@okta/okta-react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { APIUrlOne, SetOktaAuthData, SetUserId, isValidEmail } from "../../../Utils/Utils";
import axios from "axios";
import Loader from "../../Loader/Loader";
import OktaSignIn from '@okta/okta-signin-widget';
import JobsLogo from "../../../Assets/JobsOhioLogo.jpeg"
import { authClient } from "../../../Utils/Common";
import Checkbox from '@mui/material/Checkbox';


const Login = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { authState, authService } = useOktaAuth();
    const [mfaCode, setMfaCode] = useState('');
    const [userDetails, setUserDetails] = useState({
        emailAddress: '',
        password: ''
    })
    const [saveUserEmail, setSaveUserEmail] = useState('');
    const validateInputs = () => {
        if (!userDetails?.emailAddress || userDetails?.emailAddress?.trim() === '') {
            toast.error("Please Enter Email address");
            return false
        }
        if (!isValidEmail(userDetails?.emailAddress)) {
            toast.error("Please Enter Valid Email Address");
            return false;
        }
        if (!userDetails?.password || userDetails?.password?.trim() === '') {
            toast.error("Please Enter Password");
            return false;
        }
        return true
    }
    const auth = useOktaAuth();
    const loggingIn = (e) => {
        // e?.preventDefault();
        // if (!validateInputs()) return;
        // setLoading(true);

        // auth.oktaAuth.signIn({ username: userDetails?.emailAddress, password: userDetails?.password })
        //     .then(result => {
        //         setLoading(false)
        //         SetOktaAuthData("auth", result);
        //         if (result?.status === "SUCCESS") {
        //             const email = result?.user?.profile?.login.charAt(0).toUpperCase() + result?.user?.profile?.login.slice(1);
        //             setSaveUserEmail(email);
        //             login(email);

        //         }
        //         return auth.oktaAuth.isAuthenticated();
        //     })
        //     .catch((error) => {
        //         setLoading(false)
        //         toast.error(error?.message);
        //     });
        authClient?.token
            .getWithRedirect({
                responseType: ['token', 'id_token'],
                state: 'defaultrandomstring',
            })
            .catch(function (err) {
                console.error('Error OKTA login redirect', err)
            })
    };


    const login = (email) => {
        setLoading(true);
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
            },
            url: `${APIUrlOne()}/v1/get_user_id?email=${email}`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                if (e?.status === 200) {
                    SetUserId('userId', e?.data?.user_id);
                    toast.success("User login successfully");
                    setUserDetails({
                        emailAddress: '',
                        password: ''
                    });
                    navigate('/dashboard');
                }
            })
            .catch(() => {
                setLoading(false)
            });
    }

    // const [widget, setWidget] = useState(null);

    // useEffect(() => {
    //     const oktaSignIn = new OktaSignIn({
    //         baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
    //         clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    //         redirectUri: window.location.origin + '/login/callback',
    //         authParams: {
    //             pkce: true,
    //             responseType: ['id_token', 'token'],
    //             scopes: ['openid', 'profile', 'email'],
    //         },
    //     });

    //     oktaSignIn.renderEl({
    //         el: '#okta-sign-in-widget',
    //         theme: {
    //             brand: {
    //                 logo: 'https://www.example.com/logo.png',
    //                 color: '#0072c6',
    //             },
    //         },
    //     }, (response) => {
    //         if (response.status === 'SUCCESS') {
    //             window.location.href = '/dashboard';
    //         }
    //     });

    //     setWidget(oktaSignIn);

    //     return () => {
    //         OktaSignIn.remove();
    //     };
    // }, []);


    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [showMore, setShowMore] = useState(false);
    const [CheckboxSelected, setCheckboxSelected] = useState(false);
    const loggingOut = async () => auth.oktaAuth.signOut();

    const handleReadMore = () => {
        setShowMore(!showMore);
    };
    const DisableLogin = () => {
        if (CheckboxSelected === false) {
            toast.error("Please tick the checkbox to Login")
            return false
        }
        return true
    }

    return (
        <>
            {
                loading ? <Loader /> : null
            }
            <section className="login-section-main">
                <div className="section-login-after-main">
                    {/* <img src="../images/logo.svg" alt="logo" className="logo-login" /> */}
                    <div>
                        <img src={JobsLogo} alt="logo" className="logo-login" /></div>
                    <div className="login-card-main">
                        <div className="login-card-content-section">

                            <div className="heading-content-section">
                                <h2>Login with Okta</h2>
                                {/* <p>Click the below button to login with okta</p> */}

                                <div className="Useragreement">
                                    <p className="Login-screen-agree-heading">JOI User Agreement</p>
                                </div>

                                <div className="login-screen-hide-data">
                                    <p>
                                    The data and information contained in this portal are CONFIDENTIAL and strictly intended for official JobsOhio INTERNAL USE only.       <span className="login-page-reacd-more" onClick={handleReadMore}>
                                            {/* {showMore ? '' : 'Read More'} */}
                                        </span>
                                    </p>
                                    {/* {showMore && ( */}
                                    <div className="login-screen-hide-data">
                                        <p>
                                        In order to access the JobsOhio Intelligence (“JOI”) portal and use the data and information contained in it, you must be an Authorized User. For purposes of this User Agreement, an “Authorized User” is a JobsOhio employee who has received prior written authorization from JobsOhio’s President and Chief Investment Officer or Vice President of Strategy.        </p>
                                        <p>
                                        The information contained in the JOI portal is not for external distribution or data monetization for profit. Authorized Users should only share information and data from the JOI portal with other Authorized Users. The data and information contained in the JOI portal shall not be used, reproduced, distributed, or communicated to any unauthorized persons, external stakeholders, or other third parties.    </p>
                                        <p>
                                        All use of the JOI portal is monitored for security and administrative purposes. When you access this portal, you are consenting to such monitoring. Use of the JOI portal in violation of these terms is strictly prohibited and constitutes a violation of the JobsOhio Standards of Conduct and the JobsOhio Employee Handbook.
                                        </p>
                                    </div>
                                    {/* )} */}

                                </div>
                                <div className="Login-screencheckbox">
                                    <Checkbox {...label} checked={CheckboxSelected} onChange={(e) => setCheckboxSelected(e.target.checked)} size="small" />
                                    <p >By checking this box and clicking “Submit” below, you are agreeing to the terms and conditions set forth above.</p>
                                </div>

                                <form onSubmit={loggingIn}>
                                    {/* <div className="email-address-section">
                                        <label className="labels-login">Email address</label>
                                        <input type="text" className="email-address-input" placeholder="esteban_schiller@gmail.com" onChange={(e) => setUserDetails({ ...userDetails, emailAddress: e?.target?.value })} value={userDetails?.emailAddress} />
                                    </div> */}
                                    {/* <div className="password-section-login">
                                        <label className="labels-login">Password</label>
                                        <input type="password" className="email-address-input" placeholder="**********" onChange={(e) => setUserDetails({ ...userDetails, password: e?.target?.value })} value={userDetails?.password} />
                                    </div> */}
                                    {/* <div className="keep-logged-in-section">
                                        <input type="checkbox" id="keepLoggedIn" />
                                        <label htmlFor="keepLoggedIn">Keep me logged in</label>
                                    </div> */}
                                    <div className="login-button-section">
                                        {!CheckboxSelected ?
                                            <button type="button" onClick={DisableLogin} className="disablebutton" >Submit</button>
                                            :
                                            <button type="button" onClick={loggingIn}>Submit</button>
                                        }
                                    </div>
                                    <br></br>
                                    <div className="login-button-section">
                                        <p>Do you need access to JOI?</p>
                                    </div>
                                    <div className="request-access-section">
                                        Download the instruction guide below to request access.
                                    </div>
                                    <div className="login-button-section"><a href="https://aistaticcontenthosting.blob.core.windows.net/$web/Request Access to JOI(1).pdf">Request Access</a></div>
                                    <br></br>
                                    <div className="request-access-section">
                                    The JOl portal is optimized for use on Desktop/Laptop with Chrome, Edge, or Firefox browsers.<br></br> Mac OS
                                    Safari users, please download the instructions guide before logging into the platform.
                                    </div>
                                    <div className="login-button-section"><a href="https://aistaticcontenthosting.blob.core.windows.net/$web/JOI-Safari-Support.pdf">Download Mac OS Safari Instructions</a></div>
                                    
                                    {/* <div className="separator">
                                        <span>OR</span>
                                    </div> */}

                                    <div className="login-button-section-okta">
                                        {
                                            auth.authState?.isAuthenticated ?
                                                <button type="submit" onClick={loggingOut}
                                                >Logout</button> :
                                                null
                                            // <button type="submit" onClick={loggingIn}
                                            // >Login with Okta</button> 
                                        }

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Login;

// import React, { useEffect, useState } from "react";
// import './Login.css';
// import { useOktaAuth } from "@okta/okta-react";
// import { Navigate, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { APIUrlOne, SetOktaAuthData, SetUserId, isValidEmail } from "../../../Utils/Utils";
// import axios from "axios";
// import Loader from "../../Loader/Loader";
// import JobsLogo from "../../../Assets/JobsOhioLogo.jpeg"

// const Login = () => {
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();
//     const [userDetails, setUserDetails] = useState({
//         emailAddress: '',
//         password: ''
//     })
//     const [saveUserEmail, setSaveUserEmail] = useState('');
//     const validateInputs = () => {
//         if (!userDetails?.emailAddress || userDetails?.emailAddress?.trim() === '') {
//             toast.error("Please Enter Email address");
//             return false
//         }
//         if (!isValidEmail(userDetails?.emailAddress)) {
//             toast.error("Please Enter Valid Email Address");
//             return false;
//         }
//         if (!userDetails?.password || userDetails?.password?.trim() === '') {
//             toast.error("Please Enter Password");
//             return false;
//         }
//         return true
//     }
//     const auth = useOktaAuth();
//     const loggingIn = (e) => {
//         e?.preventDefault();
//         setLoading(true);
//         if (!validateInputs()) return;

//         auth.oktaAuth.signIn({ username: userDetails?.emailAddress, password: userDetails?.password })
//             .then(result => {
//                 setLoading(false)
//                 SetOktaAuthData("auth", result);
//                 if (result?.status === "SUCCESS") {
//                     const email = result?.user?.profile?.login.charAt(0).toUpperCase() + result?.user?.profile?.login.slice(1);
//                     setSaveUserEmail(email);
//                     login(email);

//                 }
//                 return auth.oktaAuth.isAuthenticated();
//             })
//             .catch((error) => {
//                 setLoading(false)
//                 toast.error(error?.message);
//             });
//     };

//     const login = (email) => {
//         setLoading(true);
//         const option = {
//             method: "GET",
//             headers: {
//                 "access-control-allow-origin": "*",
//                 "content-type": "application/json",
//             },
//             url: `${APIUrlOne()}/v1/get_user_id?email=${email}`,
//         };
//         axios(option)
//             .then((e) => {
//                 setLoading(false);
//                 if (e?.status === 200) {
//                     SetUserId('userId', e?.data?.user_id);
//                     toast.success("User login successfully");
//                     setUserDetails({
//                         emailAddress: '',
//                         password: ''
//                     });
//                     navigate('/dashboard');
//                 }
//             })
//             .catch(() => {
//                 setLoading(false)
//             });
//     }


//     const loggingOut = async () => auth.oktaAuth.signOut();
//     return (
//         <>
//             {
//                 loading ? <Loader /> : null
//             }
//             <section className="login-section-main">
//                 <div className="section-login-after-main">
//                     {/* <img src="../images/logo.svg" alt="logo" className="logo-login" /> */}
//                     <img src={JobsLogo} alt="logo" className="logo-login" />
//                     <div className="login-card-main">
//                         <div className="login-card-content-section">
//                             <div className="heading-content-section">
//                                 <h2>Login to Account</h2>
//                                 <p>Please enter your email and password to continue</p>
//                                 <form onSubmit={loggingIn}>
//                                     <div className="email-address-section">
//                                         <label className="labels-login">Email address</label>
//                                         <input type="text" className="email-address-input" placeholder="esteban_schiller@gmail.com" onChange={(e) => setUserDetails({ ...userDetails, emailAddress: e?.target?.value })} value={userDetails?.emailAddress} />
//                                     </div>
//                                     <div className="password-section-login">
//                                         <label className="labels-login">Password</label>
//                                         <input type="password" className="email-address-input" placeholder="**********" onChange={(e) => setUserDetails({ ...userDetails, password: e?.target?.value })} value={userDetails?.password} />
//                                     </div>
//                                     <div className="keep-logged-in-section">
//                                         <input type="checkbox" id="keepLoggedIn" />
//                                         <label htmlFor="keepLoggedIn">Keep me logged in</label>
//                                     </div>
//                                     <div className="login-button-section">
//                                         <button type="submit" onClick={loggingIn}>Login</button>
//                                     </div>
//                                     {/* <div className="separator">
//                                         <span>OR</span>
//                                     </div> */}

//                                     <div className="login-button-section-okta">
//                                         {
//                                             auth.authState?.isAuthenticated ?
//                                                 <button type="button" onClick={loggingOut}
//                                                 >Logout</button> :
//                                                 null
//                                             // <button type="submit" onClick={loggingIn}
//                                             // >Login with Okta</button>
//                                         }

//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }
// export default Login;

